import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import FinishedAvailabilityTable from "./FinishedAvailabilityTable";

const ajaxUrl = window.availabilityPlugin.ajaxUrl;
const pluginUrl = window.availabilityPlugin.pluginUrl;

const FinishedAvailability = () => {
  const containerRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const [viewport, setViewport] = useState(window.innerWidth);
  const [filters, setFilters] = useState({
    item: "",
    size: "",
    desc: "",
    hide: false,
  });
  const [data, setData] = useState([]);

  const tables = useMemo(() => {
    if (!data.reportgroups) return [];

    return data.reportgroups.map((group, i) => {
      return {
        title: group.reportgroup,
        data: group.grouplists.map((data, j) => {
          return {
            sizeCode: data.sizecodeheader,
            quantity: data.containerheader,
            items: data.itemlines.map((item, k) => {
              return {
                itemNumber: item.itemnumber,
                sizeCode: item.itemsize,
                description: item.itemdescription,
                availableNow: item.available,
                readyColor: item.readycolor,
                image: `https://network.kubepak.com:44380/PICTURES/${item.itemnumber}.jpg`,
              };
            }),
          };
        }),
      };
    });
  }, [data]);

  const getData = useCallback(async (filter) => {
    try {
      // eslint-disable-next-line no-undef
      jQuery.ajax({
        url: ajaxUrl,
        type: "POST",
        data: {
          action: "finished_availability_ajax",
          data: { filters: filter }, // Changed to match PlugAvailability
        },
        success: function (response) {
          try {
            console.log("Raw response:", response); // Temporary log
            const parsedData = JSON.parse(response);
            setData(parsedData);
          } catch (parseError) {
            console.error("Error parsing response:", parseError);
            console.log("Raw response:", response);
          }
        },
        error: function (error) {
          console.error("AJAX request failed:", error);
        },
      });
    } catch (error) {
      console.error("Error in getData:", error);
    }
  }, []);

  const pulledInitialData = useRef(false);

  useEffect(() => {
    if (!pulledInitialData.current) {
      getData(filters);
      pulledInitialData.current = true;
    }
  }, [getData, filters]);

  useEffect(() => {
    setContainerWidth(containerRef.current.offsetWidth);
  }, [containerRef]);

  useEffect(() => {
    const onResize = () => {
      setContainerWidth(containerRef.current.offsetWidth);
      setViewport(window.innerWidth);
    };

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  const handleUpdatedFilter = (event) => {
    const { name, value, type, checked } = event.target;
    const newFilters = {
      ...filters,
      [name]: type === "checkbox" ? checked : value,
    };
    setFilters(newFilters);
  };

  const handleFilterOnClick = async (value, type) => {
    const newFilters = {
      ...filters,
      [type]: value,
    };

    setFilters(newFilters);

    getData(newFilters);
  };

  return (
    <div
      id="finishedAvailability"
      className="plugin-root custom-scroll"
      ref={containerRef}
    >
      <h2 className="title">Kube-Pak Finished Availability</h2>
      <br />
      <div className="container">
        <div className="input-container">
          <button
            onClick={() => getData(filters)}
            className="custom-button"
            style={{ height: 21 }}
          >
            {"Search on =>"}
          </button>

          <div className="input-container-element">
            <label className="caption">Item #:</label>
            <input
              onKeyDown={(e) => {
                if (e.key === "Enter") getData(filters);
              }}
              value={filters.item}
              onChange={handleUpdatedFilter}
              className="text-input"
              type="text"
              name="item"
            />
          </div>

          <div className="input-container-element">
            <label className="caption">Size:</label>
            <input
              onKeyDown={(e) => {
                if (e.key === "Enter") getData(filters);
              }}
              value={filters.size}
              onChange={handleUpdatedFilter}
              className="text-input"
              type="text"
              name="size"
            />
          </div>

          <div className="input-container-element">
            <label className="caption">Description:</label>
            <input
              value={filters.desc}
              onKeyDown={(e) => {
                if (e.key === "Enter") getData(filters);
              }}
              onChange={handleUpdatedFilter}
              className="text-input"
              type="text"
              name="desc"
            />
          </div>

          <div className="input-container-element">
            <label className="caption">Hide NR/NA:</label>
            <input
              onChange={handleUpdatedFilter}
              className="checkbox"
              type="checkbox"
              name="hide"
            />
          </div>
        </div>
        <div className="button-container">
          <a
            href="https://network.kubepak.com:44380/finished/fininvtDownload.php"
            className="custom-button"
            style={{ height: 23 }}
          >
            <img src={`${pluginUrl}/assets/download.png`} alt="download" />
            <p className="btn-text">Download .CSV</p>
          </a>
        </div>
      </div>

      {tables.map((table, i) => (
        <FinishedAvailabilityTable
          handleFilterOnClick={handleFilterOnClick}
          key={i}
          containerWidth={containerWidth}
          viewport={viewport}
          table={table}
        />
      ))}
    </div>
  );
};

export default FinishedAvailability;

