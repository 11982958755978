import React, { useState } from "react";
import FinishedAvailabilityRow from "./FinishedAvailabilityRow";


const FinishedAvailabilityTable = ({ containerWidth, viewport, table, handleFilterOnClick }) => {
    const [displayPreview, setDisplayPreview] = useState(false);
    const [displayImage, setDisplayImage] = useState(null);

    const getPaddingRight = () => {
        if (viewport > 1440) {
            return 1;
        }

        if (viewport <= 1440) {
            return 1;
        }

        if (viewport > 1280) {
            return 2;
        }

        if (viewport <= 1280) {
            return 1;
        }

        if (viewport < 768) {
            return 2;
        }

        if (viewport < 600) {
            return 1
        };

        return 0;
    };

    const getSizeCodeWidth = () => {

        if (viewport < 768 ) {
            return containerWidth * 0.991 + 'px'
        }

        if (viewport >= 1024 && viewport <= 1280) {
            return containerWidth * 0.985 + 'px'
        }

        return viewport >= 1280 ? containerWidth * 0.691 : containerWidth * 0.989 + 'px';
    }

    const getQuantityWidth = () => {

        if (viewport < 768) { 
            return containerWidth * 0.435 + 'px'
        }

        if (viewport <= 1280) {
            return containerWidth * 0.433 + 'px'
        }

        return viewport >= 1280 ? containerWidth * 0.288 : containerWidth * 0.434 + 'px';
    }
    return <>
        <div className="title-container">
            <h3 className="table-title">{table.title}</h3>
        </div>
        <div className='table-container'>
            <table className="table">
                <thead >
                    <tr className='columns'>
                        <th
                            style={{ width: `${viewport >= 1280 ? containerWidth * 0.1431 : containerWidth * 0.25}px` }}
                            className='table-head text-center justify-center'>
                            Item Number
                        </th>
                        <th
                            style={{ width: `${viewport >= 1280 ? containerWidth * 0.1431 : containerWidth * 0.18}px` }}
                            className='table-head text-center justify-center'>
                            Size Code
                        </th>
                        <th
                            style={{ width: `${viewport >= 1280 ? containerWidth * 0.40 : containerWidth * 0.55}px` }}
                            className='table-head flex justify-center'>
                            Description
                        </th>
                        <th
                            style={{ width: `${viewport >= 1280 ? containerWidth * 0.1431 : containerWidth * 0.25}px` }}
                            className='table-head text-center'>
                            Quantity Available Now
                        </th>
                        <th
                            style={{ width: `${viewport >= 1280 ? containerWidth * 0.1431 : containerWidth * 0.18}px` }}
                            className='table-head text-center'>
                            Ready Color
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {table.data.map((section, i) => {
                        return <React.Fragment key={i}>

                            <tr className="columns flex w-full space-between" style={{ paddingLeft: 1, paddingRight: getPaddingRight() }}>
                                <td
                                    colSpan={3}
                                    style={{ width: getSizeCodeWidth() }}
                                    className="section-description section-size">
                                    Size Code: {section.sizeCode}
                                    {displayPreview &&
                                        <div className='image-preview'>
                                            <img src={displayImage.image} alt={displayImage.description} />
                                        </div>}
                                </td>
                                <td
                                    colSpan={2}
                                    style={{ width: getQuantityWidth() }}
                                    className="text-right section-description section-quantity">
                                    {section.quantity}
                                </td>

                            </tr>
                            {section.items.map((item, index) => {
                                return <FinishedAvailabilityRow
                                    handleFilterOnClick={handleFilterOnClick}
                                    key={index}
                                    setDisplayImage={setDisplayImage}
                                    setDisplayPreview={setDisplayPreview}
                                    item={item}
                                    containerWidth={containerWidth}
                                    viewport={viewport}
                                    isEven={index % 2 === 0}
                                />
                            })}
                        </React.Fragment>
                    })}
                </tbody>
            </table>
        </div>
    </>

}


export default FinishedAvailabilityTable;