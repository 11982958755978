import React from "react";


const FinishedAvailabilityRow = ({ containerWidth, viewport, item, isEven = false, setDisplayPreview, setDisplayImage, handleFilterOnClick }) => {
    
    const pluginUrl = window.availabilityPlugin.pluginUrl;

    const searchUrl = `https://www.google.com/search?q=${item.description}`;

    const handleOnMouseEnter = () => {
        setDisplayImage({
            image: item.image,
            description: item.description
        });
        setDisplayPreview(true);
    }

    const handleOnMouseLeave = () => {
        setDisplayPreview(false);
        setDisplayImage(null);
    }



    return (<>
        <tr className='columns'>
            <td className={`${isEven ? 'even ' : ''}content text-center border-bottom`} style={{ width: `${viewport >= 1280 ? containerWidth * 0.1431 : containerWidth * 0.25}px` }}>
                <button style={{ all: "unset", color: "#0000EE", textDecoration: "underline", cursor: 'pointer' }} onClick={() => handleFilterOnClick(item.itemNumber, 'item')}>
                    {item.itemNumber}
                </button>
            </td>
            <td className={`${isEven ? 'even ' : ''}content text-center border-bottom`} style={{ width: `${viewport >= 1280 ? containerWidth * 0.1431 : containerWidth * 0.18}px` }}>
                <button style={{ all: "unset", color: "#0000EE", textDecoration: "underline", cursor: 'pointer' }} onClick={() => handleFilterOnClick(item.sizeCode, 'size')}>
                    {item.sizeCode}
                </button>
            </td>
            <td style={{ width: `${viewport >= 1280 ? containerWidth * 0.40 : containerWidth * 0.55}px` }} className={`${isEven ? 'even ' : ''}itemDesc content border-bottom`}>
                <a className="relative" href={item.image} target="_blank" rel="noreferrer" onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave}>
                    <img width={17} height={17} src={item.image} alt={item.description} />
                </a>
                <a href={searchUrl} target="_blank" rel="noreferrer">
                    <img width={17} height={17} src={`${pluginUrl}/assets/google_search_icon.png`} alt="Google Search Icon" />
                </a>
                <button style={{ all: "unset", color: "#0000EE", textDecoration: "underline", cursor: 'pointer' }} onClick={() => handleFilterOnClick(item.description, 'desc')}>{item.description}</button>
            </td>
            <td style={{ width: `${viewport >= 1280 ? containerWidth * 0.1431 : containerWidth * 0.25}px` }} className={`${isEven ? 'even ' : ''}avail content border-bottom`}>
                {item.availableNow}</td>
            <td style={{ width: `${viewport >= 1280 ? containerWidth * 0.1431 : containerWidth * 0.18}px` }} className={`${isEven ? 'even ' : ''}avail content border-bottom`}>
                {item.readyColor}
            </td>
        </tr>
    </>)
}

export default FinishedAvailabilityRow;