import React from "react";

const PlugAvailabilityRow = ({ containerWidth, viewport, item, isEven = false, handleFilterOnClick }) => {
    return (<tr className='columns'>
        <td className={`${isEven ? 'even ' : ''}content text-center border-bottom`} style={{ width: `${viewport >= 1024 ? containerWidth * 0.072 : (viewport < 540 ? containerWidth * 0.2 : containerWidth * 0.12)}px` }}>
            <button style={{ all: "unset", color: "#0000EE", textDecoration: "underline", cursor: 'pointer' }}  onClick={() => handleFilterOnClick(item.item, 'item')}>{item.item}</button>
        </td>
        <td className={`${isEven ? 'even ' : ''}content text-center border-bottom`} style={{ width: `${viewport >= 1024 ? containerWidth * 0.072 : (viewport < 540 ? containerWidth * 0.2 : containerWidth * 0.12)}px` }}>
            <button style={{ all: "unset", color: "#0000EE", textDecoration: "underline", cursor: 'pointer' }}  onClick={() => handleFilterOnClick(item.size, 'size')}>{item.size}</button>
        </td>
        <td style={{ width: `${viewport >= 1024 ? containerWidth * 0.33 : (viewport < 540 ? containerWidth * 0.7 : containerWidth * 0.5)}px` }} className={`${isEven ? 'even ' : ''}itemDesc content border-bottom`}>
            <button style={{ all: "unset", color: "#0000EE", textDecoration: "underline", cursor: 'pointer' }} onClick={() => handleFilterOnClick(item.description, 'desc')}>{item.description}</button>
        </td>
        <td style={{ width: `${viewport >= 1024 ? containerWidth * 0.11 : (viewport < 540 ? containerWidth * 0.25 : containerWidth * 0.19)}px` }} className={`${isEven ? 'even ' : ''}avail content border-bottom`}>
            {item.currentWeek}</td>
        <td style={{ width: `${viewport >= 1024 ? containerWidth * 0.072 : (viewport < 540 ? containerWidth * 0.2 : containerWidth * 0.12)}px` }} className={`${isEven ? 'even ' : ''}avail content border-bottom`}>
            {item.weekOne}
        </td>
        <td style={{ width: `${viewport >= 1024 ? containerWidth * 0.072 : (viewport < 540 ? containerWidth * 0.2 : containerWidth * 0.12)}px` }} className={`${isEven ? 'even ' : ''}avail content border-bottom`}>
            {item.weekTwo}
        </td>
        <td style={{ width: `${viewport >= 1024 ? containerWidth * 0.072 : (viewport < 540 ? containerWidth * 0.2 : containerWidth * 0.12)}px` }} className={`${isEven ? 'even ' : ''}avail content border-bottom`}>
            {item.weekThree}
        </td>
        <td style={{ width: `${viewport >= 1024 ? containerWidth * 0.072 : (viewport < 540 ? containerWidth * 0.2 : containerWidth * 0.12)}px` }} className={`${isEven ? 'even ' : ''}avail content border-bottom`}>
            {item.weekFour}
        </td>
        <td style={{ width: `${viewport >= 1024 ? containerWidth * 0.072 : (viewport < 540 ? containerWidth * 0.2 : containerWidth * 0.12)}px` }} className={`${isEven ? 'even ' : ''}avail content border-bottom`}>
            {item.weekFive}
        </td>
    </tr>)
}

export default PlugAvailabilityRow;