import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PlugAvailabilityRow from './PlugAvailabilityRow';


const ajaxUrl = window.availabilityPlugin.ajaxUrl;
const pluginUrl = window.availabilityPlugin.pluginUrl;

const PlugAvailability = () => {

    const containerRef = useRef(null);
    const [containerWidth, setContainerWidth] = useState(0);
    const [viewport, setViewport] = useState(window.innerWidth);

    useEffect(() => {
        setContainerWidth(containerRef.current.offsetWidth);
    }, [containerRef]);


    useEffect(() => {
        const onResize = () => {
            setContainerWidth(containerRef.current.offsetWidth);
            setViewport(window.innerWidth);
        }

        window.addEventListener('resize', onResize);

        return () => {
            window.removeEventListener('resize', onResize);
        }
    }, [])

    const [filters, setFilters] = useState({
        "item": "",
        "size": "",
        "desc": "",
        "hide": false
    });

    const [data, setData] = useState([]);

    const items = useMemo(() => {
        if (!data.items) return [];


        return data.items.map((item, i) => {
            const weeks = !item.weeks ? [] : item.weeks.map((week) => {
                return week.week;
            });

            return {
                item: item.item,
                size: item.size,
                description: item.desc,
                currentWeek: weeks.length > 0 ? weeks[0] : '',
                weekOne: weeks.length > 1 ? weeks[1] : '',
                weekTwo: weeks.length > 2 ? weeks[2] : '',
                weekThree: weeks.length > 3 ? weeks[3] : '',
                weekFour: weeks.length > 4 ? weeks[4] : '',
                weekFive: weeks.length > 5 ? weeks[5] : '',
            }
        });
    }, [data]);

    const weeks = useMemo(() => {
        if (!data.weekDates) return [];

        return data.weekDates.map((week, i) => {
            return week.weekdate
        });
    }, [data]);

    const getData = useCallback(async () => {
        try {
            // eslint-disable-next-line no-undef
            jQuery.ajax({
                url: ajaxUrl,
                type: 'POST',
                data: {
                    action: 'plug_availability_ajax',
                    data: { filters }
                },
                success: function (response) {
                    setData(JSON.parse(response));
                },
                error: function (error) {
                    console.error(error);
                }
            });
        } catch (error) {
            console.error(error.message);
        }
    }, [filters]);
    const pulledInitialData = useRef(false);

    useEffect(() => {
        if (!pulledInitialData.current) {
            getData();
            pulledInitialData.current = true;
        }
    }, [getData]);

    const handleUpdatedFilter = (event) => {
        const { name, value, type, checked } = event.target;
        setFilters((prev) => {
            return {
                ...prev,
                [name]: type === 'checkbox' ? checked : value
            }
        });
    }

    const handleFilterOnClick = async (value, type) => {
        const newFilters = {
            ...filters,
            [type]: value
        }

        setFilters(newFilters);

        // eslint-disable-next-line no-undef
        jQuery.ajax({
            url: ajaxUrl,
            type: 'POST',
            data: {
                action: 'plug_availability_ajax',
                data: { filters: newFilters }
            },
            success: function (response) {
                setData(JSON.parse(response));
            },
            error: function (error) {
                console.error(error);
            }
        });
    }

    return (<div id="plugAvailability" className='plugin-root custom-scroll' ref={containerRef}>
        {/* <div className="imageContainer">
            <img src={`${pluginUrl}/assets/Printer-Friendly.png`} name="imageContainer" className="fixedContainer" style={{ width: '100px' }} alt='test-2' />
        </div> */}
        <h2 className='title'>Kube-Pak Plugs Availability</h2>

        {/* <form name="KP_Fin_List" action="https://network.kubepak.com:44380/finished/inventory.php" method="post"> */}
        <br />
        <div className='container'>
            <div className="input-container">

                <button
                    onClick={getData}
                    className='custom-button'
                    style={{height: 21}}
                >
                    {'Search on =>'}
                </button>

                <div className="input-container-element">

                    <label className="caption">Item #:

                    </label>
                    <input
                        onKeyDown={(e) => {
                            if (e.key === "Enter")
                                getData();
                        }}
                        value={filters.item}
                        onChange={handleUpdatedFilter}
                        className="text-input" type="text" name="item" />
                </div>
                <div className="input-container-element">

                    <label className="caption">Size:
                    </label>
                    <input
                        onKeyDown={(e) => {
                            if (e.key === "Enter")
                                getData();
                        }}
                        value={filters.size}
                        onChange={handleUpdatedFilter}
                        className="text-input" type="text" name="size" />
                </div>
                <div className="input-container-element" >

                    <label className="caption">Description:
                    </label>
                    <input
                        value={filters.desc}
                        onKeyDown={(e) => {
                            if (e.key === "Enter")
                                getData();
                        }}
                        onChange={handleUpdatedFilter} className="text-input" type="text" name="desc" />
                </div>
                <div className="input-container-element">
                    <label className="caption">Hide Partials:
                    </label>
                    <input onChange={handleUpdatedFilter} className="checkbox" type="checkbox" name="hide" />
                </div>
            </div>
            <div className='button-container'>
                <a href="https://network.kubepak.com:44380/plugs/invtDownload.php" style={{height: 23}} className='custom-button' type="button" target='_blank' rel="noreferrer" download>
                    <img src={`${pluginUrl}/assets/download.png`} alt='download' />
                    <p className="btn-text">Download these 6 Weeks</p>
                </a>
                <a href="https://network.kubepak.com:44380/plugs/invtDownloadAll.php" style={{height: 23}} className='custom-button' type="button" target='_blank' rel="noreferrer" download>
                    <img src={`${pluginUrl}/assets/download.png`} alt='download' />
                    <img src={`${pluginUrl}/assets/download.png`} alt='download' />
                    <p className="btn-text">Download All 52 Weeks**</p>
                </a>
            </div>
            <p className='caption'>** it may take a moment to load all 52 weeks</p>

        </div>
        <div className='table-container'>

            <div className='columns'>

            </div>
            <table className="table">
                <thead >
                    <tr className='columns'>
                        <th style={{ width: `${viewport >= 1024 ? containerWidth * 0.072 : (viewport < 540 ? containerWidth * 0.2 : containerWidth * 0.12)}px`, justifyContent: 'center' }} className='table-head'>Item</th>
                        <th style={{ width: `${viewport >= 1024 ? containerWidth * 0.072 : (viewport < 540 ? containerWidth * 0.2 : containerWidth * 0.12)}px`, justifyContent: 'center' }} className='table-head'>Size</th>
                        <th style={{ width: `${viewport >= 1024 ? containerWidth * 0.33 : (viewport < 540 ? containerWidth * 0.7 : containerWidth * 0.5)}px` }} className='table-head'>Description</th>
                        <th style={{ width: `${viewport >= 1024 ? containerWidth * 0.11 : (viewport < 540 ? containerWidth * 0.25 : containerWidth * 0.19)}px` }} className='table-head'>Ready Bay {weeks[0]}</th>
                        <th style={{ width: `${viewport >= 1024 ? containerWidth * 0.072 : (viewport < 540 ? containerWidth * 0.2 : containerWidth * 0.12)}px` }} className='table-head'>Ready {weeks[1]}</th>
                        <th style={{ width: `${viewport >= 1024 ? containerWidth * 0.072 : (viewport < 540 ? containerWidth * 0.2 : containerWidth * 0.12)}px` }} className='table-head'>Ready {weeks[2]}</th>
                        <th style={{ width: `${viewport >= 1024 ? containerWidth * 0.072 : (viewport < 540 ? containerWidth * 0.2 : containerWidth * 0.12)}px` }} className='table-head'>Ready {weeks[3]}</th>
                        <th style={{ width: `${viewport >= 1024 ? containerWidth * 0.072 : (viewport < 540 ? containerWidth * 0.2 : containerWidth * 0.12)}px` }} className='table-head'>Ready {weeks[4]}</th>
                        <th style={{ width: `${viewport >= 1024 ? containerWidth * 0.072 : (viewport < 540 ? containerWidth * 0.2 : containerWidth * 0.12)}px` }} className='table-head'>Ready {weeks[5]}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className='columns'>
                        <td style={{ height: 22, width: `${viewport >= 1024 ? containerWidth * 0.072 : (viewport < 540 ? containerWidth * 0.2 : containerWidth * 0.12)}px` }} className="content text-center border-bottom"></td>
                        <td style={{ height: 22, width: `${viewport >= 1024 ? containerWidth * 0.072 : (viewport < 540 ? containerWidth * 0.2 : containerWidth * 0.12)}px` }} className="content text-center border-bottom"></td>
                        <td style={{ height: 22, width: `${viewport >= 1024 ? containerWidth * 0.33 : (viewport < 540 ? containerWidth * 0.7 : containerWidth * 0.5)}px` }} className="itemDesc content border-bottom" > </td>
                        <td style={{ height: 22, width: `${viewport >= 1024 ? containerWidth * 0.11 : (viewport < 540 ? containerWidth * 0.25 : containerWidth * 0.19)}px` }} className="avail content border-bottom"> </td>
                        <td style={{ height: 22, width: `${viewport >= 1024 ? containerWidth * 0.072 : (viewport < 540 ? containerWidth * 0.2 : containerWidth * 0.12)}px` }} className="avail content border-bottom"> </td>
                        <td style={{ height: 22, width: `${viewport >= 1024 ? containerWidth * 0.072 : (viewport < 540 ? containerWidth * 0.2 : containerWidth * 0.12)}px` }} className="avail content border-bottom"> </td>
                        <td style={{ height: 22, width: `${viewport >= 1024 ? containerWidth * 0.072 : (viewport < 540 ? containerWidth * 0.2 : containerWidth * 0.12)}px` }} className="avail content border-bottom"> </td>
                        <td style={{ height: 22, width: `${viewport >= 1024 ? containerWidth * 0.072 : (viewport < 540 ? containerWidth * 0.2 : containerWidth * 0.12)}px` }} className="avail content border-bottom"> </td>
                        <td style={{ height: 22, width: `${viewport >= 1024 ? containerWidth * 0.072 : (viewport < 540 ? containerWidth * 0.2 : containerWidth * 0.12)}px` }} className="avail content border-bottom"> </td>
                    </tr>
                    {items.map((item, index) => <PlugAvailabilityRow handleFilterOnClick={handleFilterOnClick} key={index} item={item} isEven={index % 2 === 0} containerWidth={containerWidth} viewport={viewport} />)}
                </tbody>
            </table>
        </div>
    </div>)
}

export default PlugAvailability;