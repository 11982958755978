import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import App from './App';
import PlugAvailability from './components/PlugAvailability.jsx';
import FinishedAvailability from './components/FinishedAvailability.jsx';

// const root = ReactDOM.createRoot(document.getElementById('react-root'));
const rootElement = document.getElementById('react-root');
// root.render(
//   <React.StrictMode>
//     <PlugAvailability />
//   </React.StrictMode>
// );

if (rootElement) {
  const component = rootElement.getAttribute('data-component');


  const root = ReactDOM.createRoot(rootElement);

  switch (component) {
    case 'PlugAvailability':
      root.render(<PlugAvailability />);
      break;
    case 'FinishedAvailability':
      root.render(<FinishedAvailability />);
      break;
    default:
      root.render(<div>Unknown component</div>);
  }

  if (module.hot) {
    module.hot.accept('./App', () => {
      const NextApp = require('./App').default;
      root.render(<NextApp />);
    });
  }
}
